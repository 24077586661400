<template>
  <div class="page-error-404">
    page {{ title }} is working!
  </div>
</template>

<script lang="js">
import Error404Page from './error-404';

export default Error404Page;
</script>

<style lang="scss">
@import "./error-404";
</style>
